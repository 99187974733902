import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import styled from 'styled-components'

const Course = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const Form = styled.div`

    button {color: yellow !important}

    .response {display:none;}

    .indicates-required {display: none;}

    .emailadd {position: absolute; left: -5000px;}
  `

  const mailchimpForm = `<!-- Begin Mailchimp Signup Form -->
  <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
  <style type="text/css">
  #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
  /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
     We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
  </style>
  <div id="mc_embed_signup">
  <form action="https://carolinaxcosta.us1.list-manage.com/subscribe/post?u=dece85a2bb989dd78811b99cc&amp;id=7edede2179" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll">
  
  <div class="mc-field-group">
  <label for="mce-EMAIL">Indirizzo email  <span class="asterisk">*</span>
  </label>
  <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
  </div>
  <div id="mce-responses" class="clear">
  <div class="response" id="mce-error-response" style="display:none"></div>
  <div class="response" id="mce-success-response" style="display:none"></div>
  </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      <div class="emailadd" style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_dece85a2bb989dd78811b99cc_7edede2179" tabindex="-1" value=""></div>
      <div class="clear"><input id="sottoscriviti" type="submit" value="Sottoscriviti" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
      </div>
  </form>
  </div>
  
  <!--End mc_embed_signup-->`

  return (
    <Layout>
      <SEO title="Corso di fotografia Milano - Online | Carolina Costa" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Mini corso di fotografia</h1>

        <div className="main">
            Il corso base di fotografia è rivolto a tutti coloro che si avvicinano per la prima volta al mondo della fotografia ma anche a chi è già in possesso di generiche conoscenze da autodidatta.

            <p><strong>ONLINE →</strong> I corsi in streaming sono effettuati con l’insegnante collegato dal vivo tramite la piattaforma google meet, gli iscritti al corso riceveranno le istruzioni per collegarsi negli orari dei corsi.</p>
        </div>

        <h2>Contenuti</h2>

        <h3>La macchina fotografica</h3>
        <ul>
            <li>Parti della fotocamera</li>
            <li>Tipi di lenti</li>
        </ul>

        <h3>Le basi</h3>
        <ul>
            <li>Composizione</li>
            <li>Apertura, velocità dell'otturatore</li>
            <li>ISO e triangolo di esposizione</li>  
        </ul>

        <h3>La luce</h3>
        <ul>
            <li>Bilanciamento del bianco</li>
            <li>Modalità di scatto</li>
            <li>Compensazione dell'esposizione e misurazione della luce</li>  
        </ul>

        <h3>Inizio</h3>
        <p>03/05/2021 ore 19-21</p>

        <h3>Durata</h3>
        <p>4 lezioni teoriche di due ore</p>

        <h3>Costo</h3>
        <p>60 euro</p>

        <hr/>

        <h1>Lascia la tua email se sei interessata/o</h1>

        <Form dangerouslySetInnerHTML={{__html: mailchimpForm}}></Form>

      </AnimatedBox>
    </Layout>
  )
}

export default Course
